import axios from 'axios';
import Cookies from 'js-cookie'


const client = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: { 'Content-Type' : 'application/json; charset=UTF-8' },
  paramsSerializer: { indexes: null }
});

export default client;
