import React, { useContext } from "react";
import { Box, ChakraProvider, extendTheme, Link } from "@chakra-ui/react";
import {
  createBrowserRouter,
  RouterProvider,
  useRouteError,
} from "react-router-dom";

import AuthContext from "./contexts/auth";
import Cookies from "js-cookie";

import Query from "./routes/Public/Support/ticketQuery.jsx";
import Verification, {VerificationLoader} from "./routes/Public/Support/verification.jsx";
import Survey, { ticketLoader } from "./routes/Public/Support/ticketSurvey.jsx";
import Home from "./routes/Public/Support/home.jsx";
import { TicketCreate } from "./routes/Public/Support/ticketCreate";

const AuthProvider = ({ children }) => {
  const [data, setData] = React.useState(undefined);
  const login = (userData) => {
    Cookies.set("token", userData.token);
    setData(userData);
  };
  const logout = () => {
    Cookies.remove("token");
    setData(null);
  };

  return (
    <AuthContext.Provider value={{ data, login, logout, setData }}>
      {children}
    </AuthContext.Provider>
  );
};

function ErrorBoundary() {
  let error = useRouteError();
  if (error && error.response?.status === 403) {
    return (
      <Box bg="tomato" w="100%" p={4} color="white">
        <h1>Hata</h1>
        <p>
          Oturumunuz sona ermiş tekrar giriş yapmak için{" "}
          <Link href="/giris/">tıklayın</Link>
        </p>
      </Box>
    );
  }
  return <Home />;
}

const router = createBrowserRouter([
  {
    path: "/destek/",
    element: <TicketCreate />,
  },
  {
    path: "/sorgu/",
    element: <Query />,
  },
  {
    path: "/onay/:securityCode",
    element: <Verification />,
    loader: VerificationLoader
  },
  {
    path: "/anket/:ticketID",
    element: <Survey />,
    loader: ticketLoader,
  },
  {
    path: "*",
    element: <Home />,
  },
  {
    path: "/",
    element: <Home />,
  },
]);
export const theme = extendTheme({
  components: {
    Link: {
      baseStyle: {
        textDecoration: "underline",
      },
    },
  },
});

function App() {
  const authContext = useContext(AuthContext);
  return (
    <ChakraProvider theme={theme}>
      <AuthProvider value={authContext}>
        <RouterProvider router={router} />
      </AuthProvider>
    </ChakraProvider>
  );
}

export default App;
