import React, {useState} from "react";
import {Box, Button, Flex, Heading, Image, Input, SimpleGrid, Stack, Text, useColorModeValue} from "@chakra-ui/react";
import InfoBox from "../../../components/queryinfoBox";
import StateTag from "../../../components/stateTag";
import {Link} from "react-router-dom";


export default function TicketQuery() {
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [responseData, setResponseData] = useState({});
  const [error, setError] = useState("");
  const Handlequery = async () => {
    setLoading(true);
    setError(null);
    try {
      if (!password) {
        setError("Hata!! Takip numarası giriniz");
        setLoading(false);
        return;
      }
      const encodedPassword = encodeURIComponent(password);
      const apiUrl = `${process.env.REACT_APP_API_BASE_URL}public/tickets/${encodedPassword}/`;
      const response = await fetch(apiUrl);
      if (response.ok) {
        const data = await response.json();
        setResponseData(data);
      } else if (response.status === 404) {
        setError("Girdiğiniz takip numarasına ait kayıt bulunamadı.");
        setLoading(false);
      } else {
        setError("Girdiğiniz takip numarasına ait kayıt bulunamadı.");
        setLoading(false);
      }
    } catch (error) {
    }
    setLoading(false)
  };
  const createdAt  = new Date(responseData.created_at);
  const completedAt = new Date(responseData.completed_at);
  const formattedDate = createdAt.toLocaleDateString('tr-TR');
  const complatedformattedDate = completedAt.toLocaleDateString('tr-TR');

  return (
    <Flex
      minH={"100vh"}
      align={"center"}
      justify={"center"}
      py={12}
    >
      <Stack
        boxShadow={"2xl"}
        rounded={"xl"}
        p={12}
        spacing={8}
        align={"center"}
      >
        <Box mb={{base: "-40", sm: "-60", lg: "-72"}} boxSize={{base: "2xs", sm: "sm", "2xl": "md"}}>
          <Image src='https://inohom.b-cdn.net/newImages/inohomtrlogo.png' alt='inohom logo'/>
        </Box>
        <Stack align={"center"} spacing={2}>
          <Heading
              mt={5}
            fontWeight={"bold"}
            textTransform={"uppercase"}
            fontSize={"2xl"}
            style={{textAlign: "center"}}
            color={useColorModeValue("gray.800", "gray.200")}
          >
            SERVİS TAKİP Ekranı
          </Heading>
          <Text
            mt={10}
            maxWidth={430}
            fontSize={"lg"}
            color={"gray.500"}
            style={{textAlign: "center"}}
          >
            Size verilen sorgulama numarasını girerek destek durumunuzu görüntüleyebilirsiniz
          </Text>
          {error && (
            <Text as='b' fontSize='md' color="red.500" mt="2">
              {error}
            </Text>
          )}
        </Stack>
        <Stack
          spacing={4}
          direction={{base: "column", md: "row"}}
          w={"full"}
        >
          <Input
            onChange={(e) => setPassword(e.target.value)}
            type={"text"}
            placeholder={"Sorgu numaranızı giriniz"}
            color={useColorModeValue("gray.800", "gray.200")}
            bg={'gray.100'}
            rounded={"2xl"}
            border={0}
            _focus={{
              bg: useColorModeValue("gray.200", "gray.800"),
              outline: "none",
            }}
          />
          <Button
            isLoading={loading}
            onClick={Handlequery}
            _hover={{
              bgGradient: 'linear(to-r, red.800,red.700)',
              boxShadow: 'xl',
            }}
            bgGradient="linear(to-r, red.900,red.700)"
            rounded={"full"}
            color={"white"}
            flex={"1 0 auto"}
          >
            Sorgula
          </Button>
        </Stack>
        <Stack align={"center"} spacing={2}>
          {responseData && Object.keys(responseData).length > 0 && !error && (
              <>
                <Heading
                    textTransform={"uppercase"}
                    fontSize={"2xl"}
                    style={{textAlign: "center"}}
                    color={"red.900"}
                >
                  {(responseData.number)}
                </Heading>
                <SimpleGrid
                    columns={{base: 2, sm: 3, md: 3, lg: 4, xl: 4}}
                    gap={5}
                    mt={5}
                    color='gray.400'
                >
                  <InfoBox title="İsim:" value={(responseData.full_name)}/>
                  <InfoBox title="Telefon:" value={(responseData.phone_number)}/>
                  <InfoBox title="Adres:" value={(responseData.address)}/>
                  <Box>
                    <Text whiteSpace='nowrap' fontWeight="500">Durumu</Text>
                    <Text fontWeight="400" fontSize="sm"><StateTag state={responseData.state}/></Text>
                  </Box>
                  <InfoBox title="Oluşturulma tarihi:" value={formattedDate}/>
                  {responseData.state === "completed" && (
                      <>
                        <InfoBox title="Tamamlanma tarihi:" value={complatedformattedDate}/>
                        <InfoBox title="" value={""}/>
                        <Link style={{paddingTop: "5px"}} to={"/anket/" + responseData.number}>
                          <Button fontWeight={"bold"} color='red.900'>
                            Bizi Değerlendirin
                          </Button>
                        </Link>
                      </>
                  )}

                </SimpleGrid>
              </>
          )}
        </Stack>
      </Stack>
    </Flex>
  )
}
