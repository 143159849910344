import React, { useState } from "react";
import {
  Button,
  Flex,
  Heading,
  Icon,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import client from "../../../helpers/client";
import { useLoaderData } from "react-router-dom";
import { MdVerifiedUser } from "react-icons/md";

export async function VerificationLoader({ params }) {
  return [params.securityCode];
}

export default function Verification() {
  const securityCode = useLoaderData();
  const [verificationSuccess, setVerificationSuccess] = useState(false);

  const handleVerification = () => {
    const requestBody = {
      security_code: String(securityCode),
    };
    client
      .post(`/customers/verify_customer_number/`, requestBody)
      .then((response) => {
        // If request is successful, hide the button
        setVerificationSuccess(true);
      })
      .catch((error) => {
        // Handle error
        console.error("Verification failed:", error);
      });
  };

  return (
    <Flex minH={"100vh"} align={"center"} justify={"center"} py={12}>
      <Stack
        boxShadow={"2xl"}
        bg={useColorModeValue("white", "gray.700")}
        rounded={"xl"}
        p={10}
        spacing={8}
        align={"center"}
      >
        <Icon
          as={MdVerifiedUser}
          w={64}
          h={24}
          color={verificationSuccess ? "green.500" : "black"}
        />

        <Stack align={"center"} spacing={2}>
          <Heading
            textTransform={"uppercase"}
            fontSize={"2xl"}
            style={{ textAlign: "center" }}
            color={useColorModeValue("gray.800", "gray.200")}
          >
            {verificationSuccess
              ? "Telefonunuz Onaylandı"
              : "Telefon Numarası Onayla"}
          </Heading>
          <Text
            mt={10}
            maxWidth={430}
            fontSize={"lg"}
            color={"gray.500"}
            style={{ textAlign: "center" }}
          >
            {verificationSuccess
              ? "Telefon numaranız başarıyla onaylandı. İstediğiniz zaman bayinizle iletişime geçerek değiştirebilirsiniz."
              : "Lütfen bayiniz tarafından girilen telefon numaranızı Inohom destek servisinde onaylayınız."}
          </Text>
        </Stack>
        {!verificationSuccess && (
          <Stack
            spacing={4}
            direction={{ base: "column", md: "row" }}
            w={"full"}
          >
            <Button
              _hover={{
                bgGradient: "linear(to-r, red.800,red.700)",
                boxShadow: "xl",
              }}
              onClick={handleVerification}
              bgGradient="linear(to-r, red.900,red.700)"
              rounded={"full"}
              color={"white"}
              flex={"1 0 auto"}
            >
              Onayla
            </Button>
          </Stack>
        )}
      </Stack>
    </Flex>
  );
}
