import React from "react";
import {Button, Flex, Heading, Icon, SimpleGrid, Stack, Text, useColorModeValue} from "@chakra-ui/react";
import {BiSearchAlt} from "react-icons/bi";
import {IoIosCreate} from "react-icons/io";
import {useNavigate} from "react-router-dom";


export default function Home() {
  
  const navigate = useNavigate();
  const handleGoSupport = () => { navigate("/destek");};
  const handleGoQuery = () => { navigate("/sorgu"); };
  return (
    <Flex
      minH={"100vh"}
      align={"center"}
      justify={"center"}
      py={12}
    >
      <SimpleGrid columns={{base: 1, sm: 1, md: 2}} gap={{base: 10, sm: 20, md: 20}}>
        <Stack
          boxShadow={"2xl"}
          bg={useColorModeValue("white", "gray.700")}
          rounded={"xl"}
          p={10}
          spacing={8}
          align={"center"}
        >
          <Icon as={IoIosCreate} w={64} h={24}/>
          <Stack align={"center"} spacing={2}>
            <Heading
              textTransform={"uppercase"}
              fontSize={"2xl"}
              style={{textAlign: "center"}}
              color={useColorModeValue("gray.800", "gray.200")}
            >
              SERVİS KAYDI OLUŞTUR
            </Heading>
            <Text
              mt={10}
              maxWidth={430}
              fontSize={"lg"}
              color={"gray.500"}
              style={{textAlign: "center"}}
            >
              Cihaz arızası, kurulum, eğitim ve talep gibi istek ve şikayetlerinizi buradan oluşturabilirsiniz.
            </Text>
          
          </Stack>
          <Stack
            spacing={4}
            direction={{base: "column", md: "row"}}
            w={"full"}
          >
            <Button
              _hover={{
                bgGradient: 'linear(to-r, red.800,red.700)',
                boxShadow: 'xl',
              }}
              onClick={handleGoSupport}
              bgGradient="linear(to-r, red.900,red.700)"
              rounded={"full"}
              color={"white"}
              flex={"1 0 auto"}
            >
              Oluştur
            </Button>
          </Stack>
        </Stack>
        <Stack
          boxShadow={"2xl"}
          bg={useColorModeValue("white", "gray.700")}
          rounded={"xl"}
          p={10}
          spacing={8}
          align={"center"}
        >
          <Icon as={BiSearchAlt} w={64} h={24}/>
          <Stack align={"center"} spacing={2}>
            <Heading
              textTransform={"uppercase"}
              fontSize={"2xl"}
              style={{textAlign: "center"}}
              color={useColorModeValue("gray.800", "gray.200")}
            >
              Servis kaydını sorgula
            </Heading>
            <Text
              mt={10}
              maxWidth={430}
              fontSize={"lg"}
              color={"gray.500"}
              style={{textAlign: "center"}}
            >
              Size verilen sorgulama numarasını girerek destek durumunuzu görüntüleyebilirsiniz.
            </Text>
          </Stack>
          <Stack
            spacing={4}
            direction={{base: "column", md: "row"}}
            w={"full"}
          >
            <Button
              onClick={handleGoQuery}
              _hover={{
                bgGradient: 'linear(to-r, red.800,red.700)',
                boxShadow: 'xl',
              }}
              bgGradient="linear(to-r, red.900,red.700)"
              rounded={"full"}
              color={"white"}
              flex={"1 0 auto"}
            >
              Sorgula
            </Button>
          </Stack>
        </Stack>
      </SimpleGrid>
    </Flex>
  )
  
}
