import React, { useEffect, useState } from 'react';
import { Select } from "@chakra-ui/react";
import client from "../../helpers/client";

const CitySelect = ({ value, color, bgColor, placeholder, onChange }) => {
  const defaultColor = 'gray.500';
  const defaultBgColor = 'gray.100';
  const [cities, setCities] = useState([]);

  useEffect(() => {
    client.get('/public/cities/')
      .then((response) => setCities(response.data))
      .catch((error) => console.error('Error fetching cities:', error));
  }, []);

  return (
    <Select
      color={color ? undefined : defaultColor}
      bg={bgColor ? undefined : defaultBgColor}
      value={value}
      placeholder={placeholder ? undefined : "Şehir seçiniz"}
      variant='outline'
      onChange={onChange}
      w="100%"
    >
      {cities.map(city => (
        <option key={city.name} value={city.url}>{city.name}</option>
      ))}
    </Select>
  );
};

export default CitySelect;
