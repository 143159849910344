import React, {useEffect, useState} from "react";
import {
    Box,
    Button, Container,
    Flex,
    Heading,
    HStack,
    Image, Spacer,
    Stack,
    Table,
    Tbody,
    Td,
    Text,
    Tr,
    useColorModeValue,
} from "@chakra-ui/react";
import {useLoaderData} from "react-router";
import {FaStar} from "react-icons/fa6";


const StarRating = ({onChange}) => {
  const [hover, setHover] = useState(0);
  const [rating, setRating] = useState(null);
  useEffect(() => {
    onChange(rating)
  }, [rating])

  return (
    <HStack>
      {[...Array(5).keys()].map((index) => {
        const ratingValue = index + 1;
        return (
          <FaStar
            cursor={"pointer"}
            size={24}
            color={ratingValue <= (hover || rating) ? "#ffc107" : "#e4e5e9"}
            transition="color 200ms"
            onClick={() => setRating(ratingValue)}
            onMouseEnter={() => setHover(ratingValue)}
            onMouseLeave={() => setHover(0)}
          />
        );
      })}
    </HStack>
  );
}
export async function ticketLoader({ params }) {
  return [params.ticketID];
}
export default function TicketQuery() {
  const ticketID = useLoaderData();
  const [success, setSuccess] = useState(false);
  const tableColor = useColorModeValue("gray.700", "gray.200");
  const [loading, setLoading] = useState(false);
  const [answer, setAnswer] = useState({});

    const questions = [
    "Teknik destek hizmetimizden genel olarak memnun musunuz?",
    "Teknik destek ekibimizle olan iletişiminizi nasıl değerlendirirsiniz?",
    "Son teknik destek talebinizde karşılaştığınız sorunu çözme hızımıza kaç puan verirsiniz?",
  ];
    const handleSurveyForm = () => {
        const requestBody = {survey_data: answer};
    const requestoptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(requestBody),
    };
    fetch(
        `${process.env.REACT_APP_API_BASE_URL}public/tickets/${ticketID}/fill_survey/`,
        requestoptions
    )
        .then(async (response) => {
          if (!response.ok) {
            throw new Error(response.status);
          }
          return response.json();
        })
        .then((data) => {
          if (data.number) {
            setSuccess(true);
          } else {
            setLoading(false);
          }
        })
        .catch(() => {
          setLoading(false);
        });
    setSuccess(true);
  };
  const handleChange = (que, answr) => {
    // https://stackoverflow.com/a/11508490
    const newAnswer = {[que]: answr};
      setAnswer(Object.assign(answer, newAnswer));
  };

  return (
      <Flex minH={"100vh"} align={"center"} justify={"center"} py={12}>
        <Stack
            boxShadow={"2xl"}
            rounded={"xl"}
            p={12}
            spacing={8}
            align={"center"}>
          <Box
              mb={{ base: "-40", sm: "-60", lg: "-72" }}
              boxSize={{ base: "2xs", sm: "sm", "2xl": "md" }}>
            <Image
                src="https://inohom.b-cdn.net/newImages/inohomtrlogo.png"
                alt="inohom logo"/>
          </Box>
          <Stack spacing={2}>
            {success ? (
                <Text as="b" fontSize="md" color="green.500" mt="2">
                  Gönderim başarılı!
                </Text>
            ) : (
                <>
                  <Heading
                      align={"center"}
                      fontWeight={"bold"}
                      textTransform={"uppercase"}
                      fontSize={"2xl"}
                      mt={10}
                  >
                    BİZİ DEĞERLENDİRİN
                  </Heading>
                  <Text
                      mt={10}
                      fontSize={"lg"}
                      color={"gray.500"}
                      style={{ textAlign: "center" }}
                  >
                  </Text>
                    {questions.map((survey) => (
                        <Text key={survey}>
                        <Table
                            minW={{ base: "90vw", md: "300px" }}
                            w="100%"
                            size="md"
                            variant="simple">
                          <Tbody>
                            <Tr>
                              <Td
                                  textAlign={"center"}
                                  color={tableColor}
                                  fontSize="lg"
                                  fontWeight="semibold">
                                  <Container alignItems={"center"} centerContent={true}>
                                  {survey}
                                      <Spacer pt={3}/>
                                  <StarRating  onChange={(value) => {
                                      handleChange(survey, value)
                                  }}/>
                                  </Container>
                              </Td>
                            </Tr>
                          </Tbody>
                        </Table>
                      </Text>
                  ))}
                  <Button
                      fontFamily={"heading"}
                      mt={8}
                      w={"full"}
                      bgGradient="linear(to-r, red.900,red.700)"
                      color={"white"}
                      _hover={{
                        bgGradient: "linear(to-r, red.800,red.700)",
                        boxShadow: "xl",
                      }}
                      isLoading={loading}
                      loadingText="Oluşturuluyor.."
                      onClick={handleSurveyForm}
                  >
                    Gönder
                  </Button>
                </>
            )}
          </Stack>
        </Stack>
      </Flex>
  );
}
