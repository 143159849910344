import React from 'react';
import {Text} from '@chakra-ui/react';
import {translateState} from '../helpers/translate';


export default function StateTag({state}) {
  const color = {
    'assignment': 'red.400',
    'cancelled': 'gray.400',
    'deleted': 'gray.400',
    'in_queue': 'teal.400',
    'in_progress': 'blue.400',
    'approval': 'green.200',
    'completed': 'green.400',
  }[state]
  return (
    <Text
      as='span'
      align='center'
      bg={color}
      color='white'
      pl='10px'
      pr='10px'
      pt='6px'
      pb='7px'
      whiteSpace='nowrap'
      fontWeight='bold'
      fontSize={12}
      
      rounded='md'> {translateState(state)}</Text>
  )
}
