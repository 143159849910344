export function translateState(state) {
  return {
    assignment: "Talep Alındı",
    cancelled: "İptal Edildi",
    deleted: "Silindi",
    in_queue: "Yetkili Servise Yönlendirildi",
    in_progress: "Servis Süreci Başlatıldı",
    approval: "Tamamlandı",
    completed: "Tamamlandı",
  }[state] || state;
}
export function translateError(errorMsg) {
  return {
    'Can not change state if ticket is cancelled or deleted.':
      'Silinmiş ya da iptal edilmiş bir İş emrinin durumu değiştirilemez.',
    'Ticket must be assigned to start the task.':
      'Görevin başlatılabilmesi için İş emrinin atanmış olması gereklidir.',
    'Ticket must be assigned to be started.':
      'İş emrirleri bayi ataması olmadan başlatılamaz.',
    'Task must be approval to be approved.':
      'Görevin onaylanması için onay olması gerekir.',
    'Task must be started to be completed.':
      'Görevin tamamlanması için başlatılması gerekir.',
    'Task must be assigned to be started.':
      'Görevler bayi ataması olmadan başlatılamaz.',
    'There are incomplete tasks under ticket.':
      'İş emri altında tamamlanmamış görevler var.',
    'This field may not be blank.':
      'Bu alan boş bırakılamaz.',
     'Enter a valid phone number.':
      'Geçerli bir telefon numarası girin.',
    'Enter a valid email address.':
      'Geçerli bir e-posta adresi girin.',
    'The phone number entered is not valid.':
      'Girilen telefon numarası geçerli değil.',
    'Security code is not valid':
      'Girilen doğrulama kodu doğru değil.',
    'This field is required.':
      'Bu alanı doldurmak zorunludur.',
    'You do not have permission to perform this action.':
      'Bu eylemi gerçekleştirme izniniz yok.'
    
  }[errorMsg] || errorMsg;
}
