import { Text, Box } from "@chakra-ui/react";

export default function InfoBox(props) {
  const { title, value } = props;
  return (
    <Box>
      { title && <Text whiteSpace='nowrap' fontWeight="500">{title}</Text> }
      <Text fontWeight="700" color={"blackAlpha.800"} as='b' fontSize="sm">{value}</Text>
      {props.children}
    </Box>
  );
}
